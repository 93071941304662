<template>
  <v-container
    id="user-profile-view"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="7"
      >
        <validation-observer
          ref="profile_form"
          v-slot="{ handleSubmit }"
        >
          <material-card
            color="primary"
            icon="mdi-account-outline"
          >
            <template #title>
              {{ $t('edit-profile') }}
            </template>
            <v-form @submit.prevent="handleSubmit(handleProfileSubmit)">
              <v-container class="py-0">
                <v-row>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="$t('firstName').toLowerCase()"
                    >
                      <v-text-field
                        v-model="form.first_name"
                        :error-messages="errors"
                        color="primary"
                        :placeholder="$t('firstName')"
                        prepend-icon="mdi-account-box"
                        validate-on-blur
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="$t('lastName').toLowerCase()"
                    >
                      <v-text-field
                        v-model="form.last_name"
                        :error-messages="errors"
                        color="primary"
                        :placeholder="$t('lastName')"
                        prepend-icon="mdi-account-box"
                        validate-on-blur
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|email"
                      :name="$t('email').toLowerCase()"
                    >
                      <v-text-field
                        v-model="form.email"
                        :error-messages="errors"
                        color="primary"
                        :placeholder="$t('email')"
                        prepend-icon="mdi-email"
                        validate-on-blur
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    class="text-right"
                  >
                    <v-btn
                      :color="processing ? 'gray' : 'primary'"
                      min-width="150"
                      type="submit"
                    >
                      <i
                        v-if="processing"
                        class="fas fa-spinner fa-spin"
                      ></i>
                      <template v-else>
                        {{ $t('update_profile') }}
                      </template>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </material-card>
        </validation-observer>
      </v-col>

      <v-col
        cols="12"
        md="5"
      >
        <validation-observer
          ref="password_form"
          v-slot="{ handleSubmit }"
        >
          <material-card
            color="primary"
            icon="mdi-account-key"
          >
            <template #title>
              {{ $t('update_password') }}
            </template>

            <v-form @submit.prevent="handleSubmit(handlePasswordSubmit)">
              <v-container class="py-0">
                <v-row>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      vid="password"
                      rules="required|min:8"
                      :name="$t('password').toLowerCase()"
                    >
                      <v-text-field
                        v-model="form.password"
                        :error-messages="errors"
                        color="primary"
                        type="password"
                        :placeholder="$t('password')"
                        prepend-icon="mdi-account-box"
                        validate-on-blur
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|confirmed:password"
                      :name="$t('confirmPassword').toLowerCase()"
                    >
                      <v-text-field
                        v-model="form.password_confirmation"
                        :error-messages="errors"
                        color="primary"
                        type="password"
                        :placeholder="$t('confirmPassword')"
                        prepend-icon="mdi-account-box"
                        validate-on-blur
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    class="text-right"
                  >
                    <v-btn
                      :color="processing ? 'gray' : 'primary'"
                      min-width="150"
                      type="submit"
                    >
                      <i
                        v-if="processing"
                        class="fas fa-spinner fa-spin"
                      ></i>
                      <template v-else>
                        {{ $t('update_password') }}
                      </template>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </material-card>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex';
  import serverError from '../mixins/serverError';
  export default {
    name: 'UserProfileView',

    mixins: [serverError],

    data: () => ({
      form: {
        password: null,
        password_confirmation: null,
        last_name: null,
        first_name: null,
        email: null,
      },
    }),

    computed: {
      ...mapGetters({
        me: 'auth/getMe',
        processing: 'global/getProcessing',
      }),
    },

    mounted() {
      this.form = { ...this.me };
    },

    methods: {
      handlePasswordSubmit() {
        console.log('ref', this.$refs.password_form);
        if (!this.processing) {
          const payload = { ...this.form };
          this.$store.dispatch('auth/updateProfile', payload)
            .then(() => {
              this.form.password = null;
              this.form.password_confirmation = null;
              this.$refs.password_form.reset();
              this.$toasted.success(this.$t('password_updated'));
            })
            .catch(error => this.displayErrors(error));
        }
      },
      handleProfileSubmit() {
        if (!this.processing) {
          const payload = { ...this.form };
          this.$store.dispatch('auth/updateProfile', payload)
            .then(() => {
              this.$toasted.success(this.$t('profile_updated'));
            })
            .catch(error => this.displayErrors(error));
        }
      },
    },
  };
</script>
