var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"user-profile-view","fluid":"","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('validation-observer',{ref:"profile_form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('material-card',{attrs:{"color":"primary","icon":"mdi-account-outline"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('edit-profile'))+" ")]},proxy:true}],null,true)},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleProfileSubmit)}}},[_c('v-container',{staticClass:"py-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('firstName').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"primary","placeholder":_vm.$t('firstName'),"prepend-icon":"mdi-account-box","validate-on-blur":""},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('lastName').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"primary","placeholder":_vm.$t('lastName'),"prepend-icon":"mdi-account-box","validate-on-blur":""},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":_vm.$t('email').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"primary","placeholder":_vm.$t('email'),"prepend-icon":"mdi-email","validate-on-blur":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":_vm.processing ? 'gray' : 'primary',"min-width":"150","type":"submit"}},[(_vm.processing)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):[_vm._v(" "+_vm._s(_vm.$t('update_profile'))+" ")]],2)],1)],1)],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('validation-observer',{ref:"password_form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('material-card',{attrs:{"color":"primary","icon":"mdi-account-key"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('update_password'))+" ")]},proxy:true}],null,true)},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handlePasswordSubmit)}}},[_c('v-container',{staticClass:"py-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"password","rules":"required|min:8","name":_vm.$t('password').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"primary","type":"password","placeholder":_vm.$t('password'),"prepend-icon":"mdi-account-box","validate-on-blur":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required|confirmed:password","name":_vm.$t('confirmPassword').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"primary","type":"password","placeholder":_vm.$t('confirmPassword'),"prepend-icon":"mdi-account-box","validate-on-blur":""},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})]}}],null,true)})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":_vm.processing ? 'gray' : 'primary',"min-width":"150","type":"submit"}},[(_vm.processing)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):[_vm._v(" "+_vm._s(_vm.$t('update_password'))+" ")]],2)],1)],1)],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }